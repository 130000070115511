import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Stack, Subtitle, Title } from "@secuis/ccp-react-components";
import { useTrackingVisited } from "../../../store/hooks/TrackingHooks";
import SelectClientBanner from "./SelectClientBanner";
import ClientsList from "./ClientsList";
import { Section, TopSection, Wrapper } from "../../../styles/Page.styles";
import OrganizationEditorSelectors from "../../../store/selectors/OrganizationEditorSelectors";
import { organizationEditorActions } from "../../../store/reducers/OrganizationEditorSlice";
import { NodeLabel, NodeModel } from "../../../models/OrganizationNodeModel";
import OrganizationSelectors from "../../../store/selectors/OrganizationSelectors";

interface CreateGroupsProps {
    onContinueClick?: () => void;
}

const SelectClient: React.FC<CreateGroupsProps> = ({ onContinueClick }) => {
    useTrackingVisited("Select Client");
    const { t } = useTranslation();
    const clientId = useSelector(OrganizationEditorSelectors.selectSelectedClientId);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const dispatch = useDispatch();

    const availableClientNodes = useSelector(OrganizationSelectors.selectAuthorizedClients);

    useEffect(() => {
        if (availableClientNodes && availableClientNodes.length === 1) {
            setSelectedClientId(availableClientNodes[0].id);
        }
    }, [availableClientNodes]);

    useEffect(() => {
        if (!clientId) {
            return;
        }
        setSelectedClientId(clientId);
    }, [clientId]);

    const onClientSelectionChange = (clientId: string) => {
        setSelectedClientId(clientId);
        setValidationError(null);
    };

    const goToGroups = () => {
        if (!selectedClientId) {
            setValidationError(t("selectClient.list.noSelectionError"));
            return;
        }
        dispatch(organizationEditorActions.setClient(selectedClientId));
        onContinueClick();
    };

    const clientItems = useMemo(() => {
        const getClientSubtitle = (client: NodeModel) => {
            if (client.label === NodeLabel.Customer) {
                return `${t("groups.clients.customer.custNo")}: ${client.number}`;
            }
            return client.number;
        };
        return availableClientNodes.map((c) => ({
            id: c.id,
            badgeLabel: c.labelDisplay,
            title: c.name,
            subtitle: getClientSubtitle(c),
        }));
    }, [availableClientNodes, t]);

    return (
        <Wrapper direction="column" gap="S">
            <TopSection direction="row" alignItems="flex-start" justifyContent="space-between" mb="XS">
                <Stack direction="column">
                    <Title>{t("selectClient.header.title")}</Title>
                    <Subtitle>{t("selectClient.header.subtitle")}</Subtitle>
                </Stack>
                <Button fitContent mode="contained" onClick={goToGroups}>
                    {t("common.continue")}
                </Button>
            </TopSection>
            <Section>
                <SelectClientBanner />
            </Section>
            <ClientsList items={clientItems} error={validationError} selectedClientId={selectedClientId} onSelectedClientChange={onClientSelectionChange} />
        </Wrapper>
    );
};

export default SelectClient;
