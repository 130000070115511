import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../Store";

const selectUser = ({ user: { user } }: RootState) => user;

const selectUserPreference =
    (preferenceKey: string) =>
    ({ user: { user } }: RootState) => {
        if (user?.preferences) {
            const preference = user.preferences.find(({ key }) => key === preferenceKey);
            return preference?.value;
        }
        return null;
    };

const selectUserEmail = createSelector(selectUser, (user) => {
    if (user) {
        let primaryEmail = user.emails.find(({ isPrimary }) => isPrimary);
        if (!primaryEmail) {
            primaryEmail = user.emails[0];
        }
        return primaryEmail.address;
    }
    return "";
});

export default {
    selectUser,
    selectUserPreference,
    selectUserEmail,
};
