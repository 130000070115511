import mixpanel from "mixpanel-browser";
import { sha256 } from "js-sha256";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { TrackingEvent, TrackingProperties } from "../../models/TrackingModel";
import AppSelectors from "../selectors/AppSelectors";
import OrganizationSelectors from "../selectors/OrganizationSelectors";
import UserSelectors from "../selectors/UserSelectors";

export const useTracking = () => {
    const trackEvent = (event: TrackingEvent, properties?: TrackingProperties) => {
        mixpanel.track(event, properties);
    };

    return trackEvent;
};

export const useTrackingVisited = (title: string, properties?: TrackingProperties) => {
    useEffect(() => {
        mixpanel.track_pageview({ page: title, ...properties });
    }, []);
};

export const useTrackingInit = (isReady: boolean) => {
    const analyticsEnabled = useSelector(AppSelectors.selectAnalyticsEnabled);
    const user = useSelector(UserSelectors.selectUser);
    const email = useSelector(UserSelectors.selectUserEmail);
    const nodes = useSelector(OrganizationSelectors.selectAuthorizedNodes);
    const clients = useSelector(OrganizationSelectors.selectAuthorizedClients);

    const trackingInitialize = () => {
        const userId = sha256(user.id);
        mixpanel.people.set({
            "Authorized Nodes Count": nodes.length,
            "Authorized Clients Count": clients.length,
            "Is Employee": user.isEmployee || email.toLowerCase().includes("securitas") || email.toLowerCase().includes("protectas"),
        });
        mixpanel.identify(userId);
        mixpanel.opt_in_tracking();
    };

    useEffect(() => {
        if (!isReady || !user) return;

        if (analyticsEnabled) {
            trackingInitialize();
        } else {
            mixpanel.opt_out_tracking();
        }
    }, [analyticsEnabled, user, isReady]); // eslint-disable-line react-hooks/exhaustive-deps
};
