import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonText, Stack, Tabs, Tab, Icon, Title, Subtitle } from "@secuis/ccp-react-components";
import { LocationsTable } from "../LocationsTable/LocationsTable";
import CreateGroupsModal from "../CreateGroupModal";
import { BottomSection, Section, TopSection, Wrapper } from "../../styles/Page.styles";
import { GroupsTable } from "../GroupsTable/GroupsTable";
import { TableWrapper } from "./CreateGroups.styles";
import { useGroups } from "../../store/hooks/GroupsHooks";
import { useLocations } from "../../store/hooks/LocationsHooks";
import { useTrackingVisited } from "../../store/hooks/TrackingHooks";
import { GroupTreeModel } from "../../models/GroupModel";
import { EditGroupModal } from "../GroupsTable/EditGroupModal";
import { locationsActions } from "../../store/reducers/LocationsSlice";

interface CreateGroupsProps {
    onBackClick?: () => void;
}

export const MAXIMUM_NESTING_LEVEL = 6;

const CreateGroups: React.FC<CreateGroupsProps> = ({ onBackClick }) => {
    useTrackingVisited("Create Group");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [showCreateGroupModal, setShowCreateGroupModal] = useState(false);
    const [parentId, setParentId] = useState<string>();
    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState<GroupTreeModel>(null);
    useEffect(() => {
        return () => {
            dispatch(locationsActions.resetSortSettings());
        };
    }, []);

    useLocations();
    useGroups();

    const onOpenGroupsModal = (parentId?: string) => {
        setShowCreateGroupModal(true);
        setParentId(parentId);
    };

    return (
        <Wrapper direction="column" gap="S">
            <TopSection direction="column" gap="XS">
                <ButtonText icon="BackNavigation" onClick={onBackClick}>
                    {t("common.back")}
                </ButtonText>
                <Stack direction="column">
                    <Title>{t("createGroupsPage.title")}</Title>
                    <Subtitle>
                        {t("createGroupsPage.subtitle")}
                        <br />
                        <Icon size="S" variant="Info" /> {t("createGroupsPage.hierarchyLevelMessage", { count: MAXIMUM_NESTING_LEVEL + 1 })}
                    </Subtitle>
                </Stack>
            </TopSection>
            <Section>
                <Stack alignItems="flex-end">
                    <Tabs onChange={setSelectedTab} value={selectedTab}>
                        <Tab label={t("createGroupsPage.tabs.locations")} />
                        <Tab label={t("createGroupsPage.tabs.groups")} />
                    </Tabs>
                    <Box ml="M" mt="S">
                        <Button icon="Plus" mode="contained" fitContent onClick={() => onOpenGroupsModal()}>
                            {t("createGroupsPage.createGroupButton")}
                        </Button>
                    </Box>
                </Stack>
            </Section>
            <BottomSection direction="column">
                <TableWrapper direction="column">
                    {selectedTab === 0 && <LocationsTable />}
                    {selectedTab === 1 && <GroupsTable setSelectedGroup={setSelectedGroup} onOpenGroupsModal={onOpenGroupsModal} />}
                </TableWrapper>
                <CreateGroupsModal parentId={parentId} isOpen={showCreateGroupModal} onClose={() => setShowCreateGroupModal(false)} />
                <EditGroupModal group={selectedGroup} isOpen={!!selectedGroup} closeModal={() => setSelectedGroup(null)} />
            </BottomSection>
        </Wrapper>
    );
};

export default CreateGroups;
