import React from "react";
import { Breadcrumbs } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { ButtonWrapper } from "../components/PageCentered.styles";
import { useTrackingVisited } from "../store/hooks/TrackingHooks";
import { To, useLocation, useNavigate } from "react-router-dom";
import PageCentered from "../components/PageCentered";

const NotFoundPage: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { key: locationKey } = useLocation();
    useTrackingVisited("Not Found");

    const onGoBack = () => {
        const isInitialLocation = locationKey === "default";
        const navigateTo: To = isInitialLocation ? "/" : "-1";
        navigate(navigateTo);
    };

    return (
        <PageCentered title={t("notFoundPage.title")} message={t("notFoundPage.message")}>
            <ButtonWrapper>
                <Breadcrumbs label={t("common.goBack")} onClick={onGoBack} />
            </ButtonWrapper>
        </PageCentered>
    );
};

export default NotFoundPage;
