import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrandingInfoModel } from "../../models/BrandingInfoModel";
import { ErrorModel } from "../../models/ErrorModel";
import { appApi } from "../services/AppApi";

export enum HubEnum {
    WebHub = "mys_web",
    NativeHub = "mys_app",
}

interface AppStateModel {
    hub: HubEnum;
    signOnToken: string;
    languages: string[];
    analyticsEnabled?: string;
    errors: ErrorModel[];
    brandingInfo: BrandingInfoModel;
}

export const initialState: AppStateModel = {
    hub: null,
    signOnToken: null,
    languages: [],
    errors: [],
    brandingInfo: null,
};

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setEmbeddingHub(state, { payload }: PayloadAction<HubEnum>) {
            state.hub = payload;
        },
        setSignOnToken(state, { payload }: PayloadAction<string>) {
            state.signOnToken = payload;
        },
        setLanguages(state, { payload }: PayloadAction<string[]>) {
            state.languages = payload;
        },
        setAnalyticsEnabled(state, { payload }: PayloadAction<string>) {
            state.analyticsEnabled = payload;
        },
        setAppError(state, { payload }: PayloadAction<ErrorModel>) {
            state.errors.push(payload);
        },
        removeAppError(state, { payload }: PayloadAction<ErrorModel>) {
            state.errors = state.errors.filter(({ timestamp }) => timestamp !== payload.timestamp);
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(appApi.endpoints.getBrandingInfo.matchFulfilled, (state, action) => {
            state.brandingInfo = action.payload;
        });
    },
});

export const appActions = appSlice.actions;
